<template>
  <div class="vision">
    <div
      class="vision__container col-11 col-sm-10 m-auto"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div class="vision__content">{{ title }}</div>
      <div class="vision__block d-sm-flex">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="vision__block__item"
        >
          <img v-lazy="item.img" alt="vision" />
          <div class="vision__block__content">
            <div class="vision__block__content--name">
              <div>
                <span class="vision__block__content--title">{{
                  item.title
                }}</span>
                <h3 class="vision__block__content--sub">{{ item.sub }}</h3>
              </div>
              <img v-lazy="item.icon" alt="vision-icon" />
            </div>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Vision',
  data() {
    return {
      title: this.$t('about_us').vision.title,
      cards: this.$t('about_us').vision.cards,
      data: [
        {
          img: '/images/aboutus/mission1.webp',
          icon: '/images/icons/aboutus/mission.svg'
        },
        {
          img: '/images/aboutus/vision1.webp',
          icon: '/images/icons/aboutus/vision.svg'
        }
      ]
    };
  },
  mounted() {
    this.data = this.data.map((item, indx) => ({
      ...item,
      title: this.cards[indx].title,
      sub: this.cards[indx].sub,
      content: this.cards[indx].content
    }));
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
      this.title = this.$t('about_us').vision.title;
      this.cards = this.$t('about_us').vision.cards;
      this.data = this.data.map((item, indx) => ({
        ...item,
        title: this.cards[indx].title,
        sub: this.cards[indx].sub,
        content: this.cards[indx].content
      }));
    }
  }
};
</script>

<style lang="scss" scroped>
.vision {
  width: 100%;
  background-color: #fef8f4;
  padding: 50px 0;
  .vision__container {
    display: flex;
    flex-direction: column;
    gap: 58px;
    margin: 0 auto;
  }
  .vision__content {
    position: relative;
    font-weight: 700;
    font-size: 40px;
    color: var(--blue-text);
    text-align: center;
    z-index: 0;
    margin: 20px 0;
    &::after {
      content: '';
      position: absolute;
      background-color: var(--orange-background);
      border-radius: 50%;
      width: 38px;
      height: 38px;
      z-index: -1;
      top: 0;
      left: -18px;
    }
  }
  .vision__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 60px;
    height: auto;
    width: 100%;
  }
  .vision__block__item {
    display: flex;
    justify-content: space-between;
    gap: 60px;
    & > img {
      width: 48%;
      height: 100%;
      object-fit: cover;
    }
  }
  .vision__block__item:nth-child(2) {
    flex-direction: row-reverse;
  }

  .vision__block__content {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 26px 40px;
    background-color: white;
    min-height: 300px;
    border-radius: 10px;
    border: 1px solid var(--orange);
    font-size: 18px;
    p {
      margin-bottom: 0;
    }
  }
  .vision__block__content--name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    gap: 20px;
    img {
      width: 78px;
      height: 78px;
    }
    .vision__block__content--title {
      font-weight: 400;
      font-size: 18px;
      color: var(--orange);
    }
    .vision__block__content--sub {
      font-weight: 700;
      font-size: 24px;
      color: var(--blue-text);
    }
  }

  .collapse-vision {
    .vision__block__content--title {
      font-weight: 400;
      font-size: 18px;
      color: var(--orange);
    }
    .vision__block__content--sub {
      font-weight: 700;
      font-size: 24px;
      color: var(--blue-text);
    }
  }

  @media (max-width: 1024px) {
    padding: 50px 0;
    .vision__block {
      height: auto;
      flex-direction: column-reverse;
      gap: 0px;
    }
    .vision__block__item {
      margin: 20px 0;
      flex-direction: column;
      justify-content: center;
      & > img {
        width: 100%;
      }
    }
    .vision__block__item:nth-child(2) {
      flex-direction: column;
    }
    .vision__block__content {
      width: 100%;
    }
    .vision__block__image {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .vision__content {
      font-size: 32px;
    }
    .vision__container {
      gap: 20px;
    }
    .vision__block__image {
      display: none;
      width: 100%;
      margin-bottom: 16px;
    }
    .vision__block__content {
      padding: 16px;
    }
  }
}
</style>
